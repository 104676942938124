import PropTypes from "prop-types";
// import React, { lazy, Suspense } from "react";
import React from "react";
import { graphql } from "gatsby";
import { ThemeContext } from "../layouts";
import Seo from "../components/Seo";
import Hero from "../components/Hero";
import AppProperties from "../components/AppProperties";
import { FormattedMessage } from "react-intl";

// const AppProperties = lazy(() => import("../components/AppProperties"));
// const Hero = lazy(() => import("../components/Hero"));

class IndexPage extends React.Component {
  separator = React.createRef();

  scrollToContent = e => {
    this.separator.current.scrollIntoView({ block: "start", behavior: "smooth" });
  };

  render() {
    const {
      data: {
        posts: { edges: posts = [] },
        bgDesktop: {
          resize: { src: desktop }
        },
        bgTablet: {
          resize: { src: tablet }
        },
        bgMobile: {
          resize: { src: mobile }
        },
        site: {
          siteMetadata: { facebook }
        }
      }
    } = this.props;

    const backgrounds = {
      desktop,
      tablet,
      mobile
    };

    return (
      <React.Fragment>
        {/*<Suspense fallback={<div>Loading Header...</div>}>*/}
        <ThemeContext.Consumer>
          {theme => (
            <Hero scrollToContent={this.scrollToContent} backgrounds={backgrounds} theme={theme}/>
          )}
        </ThemeContext.Consumer>
        {/*</Suspense>*/}


        <hr ref={this.separator}/>

        {/*<Suspense fallback={<div>Loading App properties...</div>}>*/}
        <ThemeContext.Consumer>
          {theme => <AppProperties posts={posts} theme={theme}/>}
        </ThemeContext.Consumer>
        {/*</Suspense>*/}


        <Seo facebook={facebook}/>

        <style jsx>{`
          hr {
            margin: 0;
            border: 0;
          }
        `}</style>
      </React.Fragment>
    );
  }
}

IndexPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default IndexPage;

//eslint-disable-next-line no-undef
export const query = graphql`
  query IndexQueryEn {
    posts: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "//properties.en//" }, fields: { prefix: { regex: "/^\\d+$/" } } }
      sort: { fields: [fields___prefix], order: ASC }
    ) {
      edges {
        node {
          excerpt(pruneLength: 1360)
          fields {
            slug
            prefix
          }
          frontmatter {
            title
            imagedescription
            category
            author
            seotitle
            seodescription
            cover {
              children {
                ... on ImageSharp {
                  fluid(maxWidth: 800, maxHeight: 760) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        facebook {
          appId
        }
      }
    }
    bgDesktop: imageSharp(fluid: { originalName: { regex: "/hero-background/" } }) {
      resize(width: 1200, quality: 90, cropFocus: CENTER) {
        src
      }
    }
    bgTablet: imageSharp(fluid: { originalName: { regex: "/hero-background/" } }) {
      resize(width: 800, height: 1100, quality: 90, cropFocus: CENTER) {
        src
      }
    }
    bgMobile: imageSharp(fluid: { originalName: { regex: "/hero-background/" } }) {
      resize(width: 450, height: 850, quality: 90, cropFocus: CENTER) {
        src
      }
    }
  }
`;

//hero-background
